<template>
    <v-main>
        <div v-if="!isLoading">
            <vue-page-transition name="fade-in-left">
                <router-view/>
            </vue-page-transition>
        </div>
        <OverlayLoader :show="isLoading"></OverlayLoader>
    </v-main>
</template>
<script>
    import OverlayLoader from '@/app/shared/components/app-loader/Loader';
    export default {
        name: 'MainPanel',
        components: {
            OverlayLoader,
        },
        beforeRouteEnter(to, from, next) {
            next(undefined);
        },
        beforeRouteUpdate(to, from, next) {
            next(undefined);
        },
        beforeCreate() {
        },
        computed: {
            isLoading() {
              return false;
            },
        },
    };
</script>
